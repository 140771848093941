<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-card class="authBox">
                    <div slot="header">
                        <span>全部权限</span>
                        <el-checkbox v-model="allSelect" style="float: right; padding: 3px 0" @change="allSelectChange">
                            全选</el-checkbox>
                    </div>
                    <el-tree :data="data" show-checkbox ref="tree" node-key="id" :props="defaultProps"
                        @check-change='selectedChange'>
                    </el-tree>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="authBox selectedAuth">
                    <div slot="header" class="clearfix">
                        <span>已选权限</span>
                    </div>
                    <el-table ref="singleTable" :data="selected" :show-header='false' highlight-current-row
                        style="width: 100%;">
                        <el-table-column  property="label" label="名称">
                        </el-table-column>
                        <el-table-column label="操作" width="50">
                            <template slot-scope="scope">
                                <el-button type="text" size="mini" icon="el-icon-close"
                                    @click="removeHandle(scope.$index,scope.row)"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
        </el-row>
        <el-form :inline="true" style="margin-top:10px;" :model="formData">
            <el-form-item label="角色名称">
                <el-input v-model="formData.policyName" size='small' placeholder="请输入" style="width:300px;"></el-input>
            </el-form-item>
            <!-- <el-form-item label="说明">
                <el-input v-model="formData.note" size='small' placeholder="请输入" style="width:300px;"></el-input>
            </el-form-item> -->
            <el-form-item>
                <!-- :disabled = "this.selected && this.formData.policyName" -->
                <el-button type="primary" @click="createAuthHandle" size="small">创建角色</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                formData: {
                    policyName: '',
                    note: ''
                },
                data: [{
                    id: 10001,
                    label: '送审管理2333333333',
                    children: [{
                            id: 20001,
                            label: '送审管理_发送邀审信息'
                        },
                        {
                            id: 20002,
                            label: '送审管理_匹配专家'
                        }, {
                            id: 20003,
                            label: '送审管理_同批复匹'
                        }, {
                            id: 20004,
                            label: '送审管理_导出送审记录'
                        },{
                            id: 20005,
                            label: '送审管理_发送催审信息'
                        },{
                            id: 20006,
                            label: '送审管理_批量修改送审规则'
                        },{
                            id: 20007,
                            label: '送审管理_下载送审规则'
                        },{
                            id: 20008,
                            label: '送审管理_上传送审规则'
                        },{
                            id: 20009,
                            label: '送审管理_送审情况',
                            children:[{
                                id: 30012,
                                label: '送审管理_送审情况_匹配专家'
                            },{
                                id: 30013,
                                label: '送审管理_送审情况_同批复匹'
                            },{
                                id: 30014,
                                label: '送审管理_送审情况_新增专家'
                            },{
                                id: 30015,
                                label: '送审管理_送审情况_恢复'
                            },{
                                id: 30016,
                                label: '送审管理_送审情况_退回'
                            },{
                                id: 30017,
                                label: '送审管理_送审情况_撤回'
                            }]
                        },{
                            id: 20010,
                            label: '送审管理_修改送审规则'
                        },
                        {
                            id: 20011,
                            label: '送审管理_备注'
                        }
                    ]
                }, {
                    id: 10005,
                    label: '审核管理',
                    children: [{
                        id: 10006,
                        label: '评阅审核',
                        children:[{
                            id: 20018,
                            label: '审核管理_评阅审核_批量导出评阅书'
                        },{
                            id: 20019,
                            label: '审核管理_评阅审核_导出评阅信息表'
                        },{
                            id: 20020,
                            label: '审核管理_评阅审核_重新生成评阅书'
                        },{
                            id: 20021,
                            label: '审核管理_评阅审核_作废'
                        },{
                            id: 20022,
                            label: '审核管理_评阅审核_上传审核结果'
                        },]
                    }, {
                        id: 10007,
                        label: '院校发布',
                        children:[{
                            id: 20023,
                            label: '审核管理_院校发布_批量导出评阅书'
                        },{
                            id: 20024,
                            label: '审核管理_院校发布_导出评审信息表'
                        },{
                            id: 20025,
                            label: '审核管理_院校发布_交付'
                        },{
                            id: 20026,
                            label: '审核管理_院校发布_取消交付'
                        },{
                            id: 20027,
                            label: '审核管理_院校发布_预览'
                        }]
                    }]
                }, {
                    id: 10007,
                    label: '评阅定制',
                    children: [{
                        id: 10008,
                        label: '专家评阅定制',
                        children:[{
                            id: 20028,
                            label: '评阅定制_专家评阅定制_添加'
                        },{
                            id: 20029,
                            label: '评阅定制_专家评阅定制_评审信息维护'
                        },{
                            id: 20030,
                            label: '评阅定制_专家评阅定制_删除'
                        },{
                            id: 20031,
                            label: '评阅定制_专家评阅定制_电子签名'
                        }]
                    }, {
                        id: 10009,
                        label: '评阅书模板',
                        children:[{
                            id: 20032,
                            label: '评阅定制_评阅书模板_添加'
                        },{
                            // 同时需要route：10
                            id: 20033,
                            label: '评阅定制_评阅书模板_模板定制',
                            children:[{
                                id: 30035,
                                label: '评阅定制_评阅书模板_模板定制_上传评阅书模板'
                            },{
                                id: 30036,
                                label: '评阅定制_评阅书模板_模板定制_下载评阅书模板'
                            },{
                                id: 30037,
                                label: '评阅定制_评阅书模板_模板定制_测试论文模板'
                            }]
                        },{
                            id: 20034,
                            label: '评阅定制_评阅书模板_删除'
                        }]
                    }]
                },{
                    // 同时需要route：11
                    id: 10012,
                    label: '院校定制',
                    children:[{
                        id: 20038,
                        label: '院校定制_添加'
                    },{
                        // route 13
                        id: 20039,
                        label: '院校定制_规则定制'
                    },{
                        id: 20040,
                        label: '院校定制_添加学位类型'
                    },{
                        id: 20041,
                        label: '院校定制_修改单位名称'
                    },{
                        id: 20042,
                        label: '院校定制_添加子单位'
                    },{
                        id: 20043,
                        label: '院校定制_匿名'
                    },{
                        id: 20044,
                        label: '院校定制_规则定制_添加'
                    }]
                },{
                    id: 10015,
                    label: '财务管理',
                    children:[{
                        id: 10016,
                        label: '未打款',
                        children:[{
                            id: 20045,
                            label: '财务管理_未打款_导出财务表'
                        },{
                            id: 20046,
                            label: '财务管理_未打款_打款'
                        }]
                    },{
                        id: 10017,
                        label: '已打款'
                    }]
                },{
                    id: 10018,
                    label: '账号管理',
                    children:[{
                        id: 10019,
                        label: '院校账号',
                        children:[{
                            id: 20047,
                            label: '账号管理_院校账号_重置密码'
                        },{
                            id: 20048,
                            label: '账号管理_院校账号_进入'
                        }]
                    },{
                        id: 10020,
                        label: '专家账号'
                    }]
                },{
                    id: 10021,
                    label: '基础信息',
                    children:[{
                        id: 10022,
                        label: '院校信息'
                    },{
                        id: 10023,
                        label: '学科信息'
                    },{
                        id: 10024,
                        label: '专家信息'
                    }]
                },{
                    id: 10025,
                    label: '数据统计',
                    children:[{
                        id: 10026,
                        label: '送审统计'
                    },{
                        id: 10027,
                        label: '专家统计'
                    }]
                }],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                allSelect: false,
                selected: []
            }
        },
        props: ['roleId'],
        created() {
            this.getAuthoritySelect();
        },
        methods: {
            // 获取权限清单
            getAuthoritySelect() {
                let params = {
                    role_id: this.roleId
                };
                console.log(params);
                this.$api.authority.getAuthoritySelect(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.data = res.data.data;
                            console.log(this.data);
                        }
                    }).catch(err => {
                        this.$message.warning('服务器维护');
                    })
            },
            // 全选
            allSelectChange(val) {
                if (val) {
                    this.$refs.tree.setCheckedNodes(this.data);
                } else {
                    this.$refs.tree.setCheckedNodes([]);
                }
            },
            selectedChange(p1, p2, p3) {
                console.log(this.$refs.tree.getCheckedNodes(false,true));
                this.selected = this.$refs.tree.getCheckedNodes(false,true);
                // this.selected = this.$refs.tree.getCheckedNodes().filter(
                //     (item) => {
                //         // console.log(item);
                //         // return !item.hasOwnProperty('children');
                //         return item['children'];
                //         // return !item['children'];
                //     }
                // );
                console.log(this.selected);
            },
            removeHandle(index, row) {
                this.selected.splice(index, 1);
                this.$refs.tree.setCheckedNodes(this.selected);
            },
            createAuthHandle() {
                let permissionIds = [];
                this.selected.forEach(item => {
                    permissionIds.push(item.id);
                });
                let params = {
                    username:this.formData.policyName,
                    selectedAuthority: permissionIds
                };

                if(params.username != '' && params.selectedAuthority != ''){
                    this.$api.authority.postAddRoleAndPermission(params)
                    .then(res => {
                        console.log(res.data);
                        if(res.data.code == 200){
                            this.$message.success('添加成功！');
                        }else {
                            this.$message.warning(res.data.msg);
                        }
                    })}else{
                        this.$message.warning('角色名称或角色权限不能为空');
                    }
            
                // this.$api.authority.postAddRoleAndPermission(params)
                // .then(res => {
                //     if(res.data.code == 200)
                //     {
                //         this.$message.success('添加成功！');
                //     }
                //     else 
                //     {
                //         this.$message.warning('服务器维护！');
                //     }
                // })
            }
        },
        components: {

        }
    }
</script>

<style scoped>

</style>

<style>
    /* .selectedAuth .el-card__body {
        padding: 0px;
        overflow: auto;
    }

    .authBox .el-card__body {
        height: calc(100vh - 350px);
        overflow: auto;
    } */
</style>